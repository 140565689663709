import {Dispatch, SetStateAction} from 'react';
import {Operation} from 'react-datasheet-grid/dist/types';
import {DataGridRow} from '../../interfaces/dataGrid';
import {
    DirtyField,
    MassEditBackendErrors,
    MassEditBackendRelatedError, MassEditBackendRowError,
    PropertyValidation,
} from '../../interfaces/general';

// eslint-disable-next-line import/prefer-default-export
export const getDirtyFieldsInArrayOfObjects = <T extends DataGridRow>(
    array: T[],
    originalArray: T[],
    dirtyFields: DirtyField<T>[],
    setDirtyFields: Dispatch<SetStateAction<DirtyField<T>[]>>,
    propertyValidation: PropertyValidation<T>,
    backendErrors: MassEditBackendErrors<T>,
    setBackendErrors: Dispatch<SetStateAction<MassEditBackendErrors<T>>>,
    idIndexMap: Record<string, number>,
    operations: Operation[],
) => {
    const newDirtyFields = JSON.parse(JSON.stringify(dirtyFields));
    const newBackendErrors = JSON.parse(JSON.stringify(backendErrors));

    operations.forEach(operation => {
        for (let index = operation.fromRowIndex; index < operation.toRowIndex; index++) {
            const dirtyFieldsRow = {} as DirtyField<T>;
            const obj = array[index];

            Object.keys(obj).forEach((key: keyof T) => {
                if (obj[key] !== originalArray[index][key]) {
                    const validity = propertyValidation({property: key, value: obj[key]});

                    dirtyFieldsRow[key] = {
                        ...validity,
                        value: obj[key],
                    };
                }
            });

            newDirtyFields[index] = dirtyFieldsRow;

            const rowId = originalArray[index].id as string;

            if (newBackendErrors.errors[rowId] !== undefined && newBackendErrors.errors[rowId].length > 0) {
                for (let i = newBackendErrors.errors[rowId].length - 1; i >= 0; i--) {
                    const error = newBackendErrors.errors[rowId][i];

                    if (error.value === array[idIndexMap[rowId]][error.field]) {
                        return;
                    }

                    newBackendErrors.errors[rowId].splice(i, 1);

                    if (!error.relatedId) {
                        return;
                    }

                    const relIndex = newBackendErrors.relatedIds[error.relatedId].findIndex(
                        (item: MassEditBackendRelatedError<T>) => item.field === error.field,
                    );

                    if (relIndex !== -1) {
                        newBackendErrors.relatedIds[error.relatedId].splice(relIndex, 1);
                    }
                }
            }

            if (newBackendErrors.relatedIds[rowId] !== undefined && newBackendErrors.relatedIds[rowId].length > 0) {
                for (let i = newBackendErrors.relatedIds[rowId].length - 1; i >= 0; i--) {
                    const errorRelation = newBackendErrors.relatedIds[rowId][i];

                    if (errorRelation.value === array[idIndexMap[rowId]][errorRelation.field]) {
                        return;
                    }

                    newBackendErrors.relatedIds[rowId].splice(i, 1);

                    const relIndex = newBackendErrors.errors[errorRelation.id].findIndex(
                        (item: MassEditBackendRowError<T>) => item.field === errorRelation.field,
                    );

                    if (relIndex !== -1) {
                        newBackendErrors.errors[errorRelation.id].splice(relIndex, 1);
                    }
                }
            }
        }
    });

    setDirtyFields(newDirtyFields);
    setBackendErrors(newBackendErrors);
};
