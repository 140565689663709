// extracted by mini-css-extract-plugin
export var buttonsWrapper = "mass-edit-module--buttonsWrapper--JsQCy";
export var centered = "mass-edit-module--centered--+seBJ";
export var changeCounter = "mass-edit-module--changeCounter--RMgX+";
export var errorMessage = "mass-edit-module--errorMessage--RX0GI";
export var mainText = "mass-edit-module--mainText--ndwGU";
export var note = "mass-edit-module--note--hfkcT";
export var secondaryText = "mass-edit-module--secondaryText--HQaIV";
export var statusBar = "mass-edit-module--statusBar--NbwNm";
export var stepIndicator = "mass-edit-module--stepIndicator--jbmuJ";
export var success = "mass-edit-module--success--q-XsY";
export var summaryWrapper = "mass-edit-module--summaryWrapper--FzxEy";