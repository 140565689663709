import {Column, CellProps} from 'react-datasheet-grid';
import {SingleDatePicker} from 'react-dates';
import moment from 'moment';
import {useState, useEffect} from 'react';

export type DatePickerColumnnOptions = {
    disabled?: boolean,
  };

const DatePickerColumnComponent = ({
    focus,
    active,
    stopEditing,
    rowData,
    setRowData,
    columnIndex,
    disabled,

}: CellProps<string | null>) => {
    const [focused, setFocused] = useState(focus);

    useEffect(() => {
        if (active && focus) {
            setFocused(true);
        }
    }, [active, focus]);

    return (
        <SingleDatePicker
            date={rowData ? moment(rowData) : null}
            onDateChange={date => {
                setRowData(date ? moment.utc(date).format() : null);
            }}
            focused={focused}
            id={`massEditDatePicker${columnIndex}`}
            onFocusChange={newFocus => {
                setFocused(newFocus.focused);
                stopEditing({nextRow: false});
            }}
            appendToBody
            noBorder
            firstDayOfWeek={1}
            hideKeyboardShortcutsPanel
            numberOfMonths={2}
            isOutsideRange={() => false}
            displayFormat="DD/MM/YYYY"
            readOnly
            placeholder=''
            disabled={disabled}
        />
    );
};

const datePickerColumn = (
    selectData: DatePickerColumnnOptions,
): Column<string | null, DatePickerColumnnOptions> => ({
    component: DatePickerColumnComponent,
    columnData: selectData,
    disableKeys: true,
    keepFocus: true,
    disabled: selectData.disabled,
    deleteValue: () => null,
    copyValue: ({rowData}) => rowData ?? null,
    pasteValue: ({value}) => value ?? null,
});

export default datePickerColumn;
