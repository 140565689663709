import {} from 'react-table';
import {useLayoutEffect, useRef} from 'react';
import Select, {SelectInstance} from 'react-select';
import {Column, CellProps} from 'react-datasheet-grid';

export type SelectColumnOption = {
    value: string,
    label: string,
};

export type SelectColumnOptions = {
    options: SelectColumnOption[],
    disabled?: boolean,
  };

const SelectColumnComponent = ({
    focus,
    active,
    stopEditing,
    rowData,
    columnData,
    setRowData,
}: CellProps<string | null, SelectColumnOptions>) => {
    const ref = useRef<SelectInstance<SelectColumnOption>>(null);

    useLayoutEffect(() => {
        if (focus) {
            ref.current?.focus();

            return;
        }

        ref.current?.blur();
    }, [focus]);

    return (
        <Select
            ref={ref}
            menuPortalTarget={document.body}
            menuIsOpen={focus}
            styles={{
                container: provided => ({
                    ...provided,
                    flex: 1,
                    alignSelf: 'stretch',
                    pointerEvents: focus ? undefined : 'none',
                }),
                control: provided => ({
                    ...provided,
                    height: '100%',
                    border: 'none',
                    boxShadow: 'none',
                    background: 'none',
                }),
                indicatorSeparator: provided => ({
                    ...provided,
                    opacity: active ? 1 : 0,
                }),
                placeholder: provided => ({
                    ...provided,
                    opacity: active ? 1 : 0,
                }),
            }}
            onMenuClose={() => stopEditing({nextRow: false})}
            onChange={value => {
                setRowData(value?.value || null);
                setTimeout(stopEditing, 0);
            }}
            value={columnData?.options?.find(({value}) => value === rowData) ?? null}
            options={columnData.options}
        />
    );
};

const selectColumn = (
    selectData: SelectColumnOptions,
): Column<string | null, SelectColumnOptions> => ({
    component: SelectColumnComponent,
    columnData: selectData,
    disableKeys: true,
    keepFocus: true,
    disabled: selectData.disabled,
    deleteValue: () => null,
    copyValue: ({rowData}) => selectData.options.find(choice => choice.value === rowData)?.label ?? null,
    pasteValue: ({value}) => selectData.options.find(choice => choice.label === value)?.value ?? null,
});

export default selectColumn;
